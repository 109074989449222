import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { BrowserTracing } from "@sentry/tracing";
import { ReportingObserver, ExtraErrorData } from "@sentry/integrations";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

Sentry.init({
  dsn: "https://d6753470159e4ee6a88ff2293c7531d0@o44427.ingest.sentry.io/6539510",
  integrations: [
    new ReportingObserver(),
    new ExtraErrorData(),
    new BrowserTracing({
      tracingOrigins: [
        "localhost",
        /^\//,
        "grtivr-prod.regionofwaterloo.9802690.ca",
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Replay({
      // Nothing in this app is super sensitive, so we can get away with not masking.
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
  release: process.env.REACT_APP_GIT_SHA,
  environment: window.location.host,
  tracesSampleRate: 0.1,

  // Capture 5% of all sessions
  replaysSessionSampleRate: 0.05,
  // Of the remaining 50% of sessions, if an error happens start capturing
  replaysOnErrorSampleRate: 1.0,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      // Authorization: `Bearer ${token}`,
      "X-Frontend-Application": "React-Alert-Creator",
      "X-Frontend-Version": process.env.REACT_APP_GIT_SHA || "HEAD",
    },
  });

  return forward(operation).map((result) => {
    // Requires the server to emit a Access-Control-Expose-Headers header
    // containing x-request-id.
    const headers = operation.getContext().response.headers;
    const backendVersion = headers.get("x-request-id");
    // TODO: Do something with the Request ID here.
    return result;
  });
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    middlewareLink,
    createHttpLink({
      uri: "https://grtivr-prod.regionofwaterloo.9802690.ca/vms/graphql",
      credentials: "same-origin",
    }),
  ]),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
