import React, { useMemo, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { SESv2Client } from "@aws-sdk/client-sesv2";
import loadable from "@loadable/component";

const ConventionalBus = loadable(() => import("./ConventionalBus"));
const Ion = loadable(() => import("./Ion"));
const TripCancellation = loadable(() => import("./TripCancellation"));
const WeatherAlert = loadable(() => import("./WeatherAlert"));
const EmailInput = loadable(() => import("./EmailInput"));

const defaultDomain = window.location.href.includes("/keolis")
  ? "keolis.ca"
  : "regionofwaterloo.ca";

function TestMode() {
  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-4">
      <div className="ml-3">
        <p className="text-sm text-yellow-700">
          You are currently in test mode. Alerts created through this form will
          be marked with a "testing" indicator.
        </p>
      </div>
    </div>
  );
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const [email, setEmail] = useState<string>(
    window.location.href.includes("/keolis") ? "controllers@keolis.ca" : ""
  );

  const testmode =
    window.location.href.includes("onrender") ||
    window.location.href.includes("localhost");

  const sesClient = useMemo(() => {
    return new SESv2Client({
      region: "ca-central-1",
      credentials: {
        accessKeyId: "AKIATMGO2U4IWRRTLXVE",
        secretAccessKey: "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",
      },
    });
  }, []);

  const saveLabel =
    email.endsWith("@keolis.ca") ||
    (email.length === 0 && defaultDomain === "keolis.ca")
      ? "Submit alert"
      : "Save";

  const emergencyDetour = (
    <>
      <EmailInput
        email={email}
        defaultDomain={defaultDomain}
        setEmail={setEmail}
      />
      <ConventionalBus
        sesClient={sesClient}
        username={email}
        testmode={testmode}
        saveLabel={saveLabel}
      />
    </>
  );

  const ionDisruption = (
    <>
      <EmailInput
        email={email}
        defaultDomain={defaultDomain}
        setEmail={setEmail}
      />
      <Ion
        sesClient={sesClient}
        username={email}
        saveLabel={saveLabel}
        testmode={testmode}
      />
    </>
  );

  const tripCancellation = (
    <>
      <EmailInput
        email={email}
        defaultDomain={defaultDomain}
        setEmail={setEmail}
      />
      <TripCancellation
        sesClient={sesClient}
        username={email}
        saveLabel={saveLabel}
        testmode={testmode}
      />
    </>
  );

  const weatherAlert = (
    <>
      <EmailInput
        email={email}
        defaultDomain={defaultDomain}
        setEmail={setEmail}
      />
      <WeatherAlert
        sesClient={sesClient}
        username={email}
        saveLabel={saveLabel}
        testmode={testmode}
      />
    </>
  )

  return (
    <div className="App w-full mb-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
        <div className="max-w-3xl mx-auto space-y-8 divide-y divide-gray-200">
          {testmode && <TestMode />}
          <div className="sm:items-start sm:border-gray-200 sm:pt-5">
            <SentryRoutes>
              <Route path="/" element={<NavButtons />} />
              <Route path="/emergency-detour" element={emergencyDetour} />
              <Route path="/ion-disruption" element={ionDisruption} />
              <Route path="/trip-cancellation" element={tripCancellation} />
              <Route path="/weather-alert" element={weatherAlert} />
              <Route path="/keolis" element={<NavButtons />} />
              <Route path="/keolis/ion-disruption" element={ionDisruption} />
              <Route
                path="/keolis/trip-cancellation"
                element={tripCancellation}
              />
            </SentryRoutes>
          </div>
        </div>
      </div>
    </div>
  );
}

function NavButtons() {
  const navigate = useNavigate();
  return window.location.href.includes("/keolis") ? (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => navigate("/keolis/ion-disruption")}
      >
        ION service disruption
      </button>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 rounded-r-md"
        onClick={() => navigate("/keolis/trip-cancellation")}
      >
        Trip cancellation
      </button>
    </span>
  ) : (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <button
        type="button"
        className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => navigate("/emergency-detour")}
      >
        Emergency detour
      </button>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => navigate("/ion-disruption")}
      >
        ION replacement shuttle
      </button>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 rounded-r-md"
        onClick={() => navigate("/trip-cancellation")}
      >
        Bus trip cancellation
      </button>
      <button
        type="button"
        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 rounded-r-md"
        onClick={() => navigate("/weather-alert")}
      >
        Severe weather
      </button>
    </span>
  );
}

export default App;
